<style scoped>
	.container-trust-meter {
		width: 100px;
		border: 1px solid #E0E0E0;
		padding: 10px;
		border-radius: 20px;
		margin-right: 10px;
		display: flex;
		justify-content: start;
		align-items: center;
		cursor: pointer;
	}
	.icon-trust-meter {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 5px;
	}
	.text-trust-meter {
		font-weight: bold;
	}
	.vue-simple-suggest.designed .suggestions .suggest-item {
		padding: 0 !important;
	}
</style>
<template>
	<div style="max-height: 100%; overflow-x: auto">

		<modal name="inputCustomer" height="auto" :click-to-close="formClose">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<div class="card-body" style="max-height: 500px; overflow: auto;">
							<h1 class="header-title"> Form Input Customer </h1>
							<form @submit.prevent="inputData">
								<div class="form-row mt-3">
									<div class="col-md-6">
										<vue-simple-suggest v-model="dataNewCustomer.name" :styles="autoCompleteStyle" display-attribute="name" value-attribute="id" :list="getCustomerList" :filter-by-query="true" placeholder="Name*" required @select="onSuggestSelect">
											<!-- Filter by input text to only show the matching results -->
											<div slot="suggestion-item" slot-scope="{ suggestion }" style="padding: 0;">
												<div v-on:click.stop="onAutocompleteButtonClick(suggestion)">
													<span>{{ suggestion.name }}</span>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '2'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>BAD</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '4'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>GOOD</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '3'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>MODERATE</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
													<div v-on:click.stop="onAutocompleteButtonClick(suggestion)" v-show="suggestion.customer_history.trust_meter === '1'">
														<div v-b-tooltip.hover :title="suggestion.customer_history ? suggestion.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
															<div>
																<span>UNKNOWN</span>
															</div>
															<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
														</div>
													</div>
												</div>
											</div>
										</vue-simple-suggest>
										<!-- <div class="form-group">
                      <input
                        v-model="dataNewCustomer.name"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Company Name*"
                        required
                      />
                    </div> -->
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" class="form-control form-control-sm" placeholder="Email*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.name_pic" type="text" class="form-control form-control-sm" placeholder="PIC Name*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.mobile" type="number" class="form-control form-control-sm" placeholder="Mobile Phone*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.position" type="text" class="form-control form-control-sm" placeholder="PIC Position" />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<input v-model="dataNewCustomer.phone" type="number" class="form-control form-control-sm" placeholder="Office Phone*" required />
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<textarea v-model="dataNewCustomer.address" maxlength="255" rows="4" placeholder="Address*" class="form-control form-control-sm" required></textarea>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<textarea v-model="dataNewCustomer.remarks" maxlength="255" rows="4" placeholder="Remarks" class="form-control form-control-sm"></textarea>
										</div>
									</div>
									<!-- <div class="form-row mt-3"> -->
										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="">Assignee<span class="text-danger" aria-required="true"> * </span></label>
												<v-select multiple="true" v-model="assignee" :options="listUser" :reduce="(option) => option.id" label="name_user" >
												</v-select>
											</div>
										</div>
									<!-- </div> -->
									<hr />
									<div class="col-md-12 mt-1">
										<div class="float-right">
											<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
											<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormInput">Cancel</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</modal>

		<!-- form modal schedule-->
		<modal :click-to-close="formClose" name="scheduleCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar>
							<div v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Form Schedule Customer </h1>
								<form @submit.prevent="submitData">
									<div class="form-row mt-3">
										<div class="col-md-12">
											<div class="form-group">
												<label for="">Customer</label>
												<input v-model="dataCustomer.name" type="text" class="form-control form-control-sm" disabled />
											</div>
											<div class="form-group">
												<label for="">Choose a Status</label>
												<v-select v-model="status" :options="listStatus" label="name" @input="changeStatus">
												</v-select>
												<!-- <multiselect v-model="status" placeholder="Status Customer" :searchable="true" :allow-empty="true" :clearable="true" :loading="isLoading" :close-on-select="true" :options="listStatus" label="name" required @input="changeStatus"></multiselect> -->
											</div>
										</div>
										<div v-if="status === 'Visit'" class="col-md-12">
											<div class="form-group">
												<label for="">Schedule Time <span class="text-danger">*</span>
												</label>
												<datetime v-model="dataCustomer.schedule_time" format="YYYY-MM-DD h:i:s" required></datetime>
											</div>
										</div>
										<div v-if="status === 'Reject'" class="col-md-12 mt">
											<div class="form-group">
												<label for="">Choose a Reason</label>
												<v-select v-model="reason" :options="listReason" label="reason" @input="changeReason">
													<template v-slot:search="{ attributes, events }">
														<input
														class="vs__search"
														:required="!reason"
														v-bind="attributes"
														v-on="events"
														/>
													</template>
												</v-select>
											</div>
										</div>

										<div class="col-md-12">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="remark" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormSchedule">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>

							<div v-can-check="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Information</h1>
								<form @submit.prevent="submitData">
									<div class="form-row mt-3">
										<div class="col-md-12">
											You cannot edit this data because you not have access
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormSchedule">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>


		<!-- form modal Komentar-->
		<modal :click-to-close="formClose" name="commentCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar>
							<div v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Add Notes </h1>
								<form @submit.prevent="addCommentar">
									<div class="form-row mt-3">
										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormKomentar">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>

							<div v-can-check="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" class="card-body">
								<h1 class="header-title"> Information</h1>
								<form @submit.prevent="submitData">
									<div class="form-row mt-3">
										<div class="col-md-12">
											You cannot edit this data because you not have access
										</div>
										<div class="col-md-12 mt-1">
											<div class="float-right">
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormKomentar">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<!-- Modal LOG detail -->
		<modal name="logCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar style="max-height: 500px">
							<div class="card-body">
								<h1 class="header-title"> Detail Customer </h1>
								<div class="card border">
									<div class="card-body">
										<VuePerfectScrollbar style="max-height: 200px">
											<div class="row">
												<div style="margin-bottom: 20px" class="col-md-12 text-left">
													<h4>
														<img :src="
															'https://ui-avatars.com/api/?name=' +
															dataCustomer.name
														" style="
															width: 35px;
															height: 35px;
															border-radius: 8.25rem !important;
														" />
														{{ dataCustomer.name }}</h4>
												</div>
												<div class="col-md-3">
													<span><b>PIC</b> </span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.pic_name }}</span>
												</div>
												<div class="col-md-3">
													<span><b>Mail </b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.email }}</span>
												</div>

												<div class="col-md-3">
													<span><b>Mobile</b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.mobile }}</span>
												</div>
												<div class="col-md-3">
													<span><b>Office</b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.phone }}</span>
												</div>

												<div class="col-md-3">
													<span><b>Address</b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8" style="white-space: pre-line">
													<span> {{ dataCustomer.address }}</span>
												</div>
												<div class="col-md-3">
													<span><b>Remarks</b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8" style="white-space: pre-line">
													<span> {{ dataCustomer.remarks }}</span>
												</div>
											</div>
											<div class="row" style="margin-top: 30px">
												<div class="col-md-3">
													<span><b>Created By</b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.created_name }}</span>
												</div>
												<div class="col-md-3">
													<span><b>Date Created </b></span>
												</div>
												<div class="col-md-1">
													:
												</div>
												<div class="col-md-8">
													<span> {{ dataCustomer.created_on }}</span>
												</div>
											</div>
										</VuePerfectScrollbar>
									</div>
								</div>
								<!-- <span v-for="item in dataCustomerHistory" :key="item.status">
                                    {{item.status}} <br>
                                     <span v-html="item.remark"></span>
                                </span> -->
								<div class="row">
									<div class="col-md-12 bg-light p-1"> LOG </div>
									

											
											<div v-for="item in dataCustomerHistory.history" :key=" item.id" class="col-md-12 mt-1">
												<span>
													<i class="fas fa-history"> </i><span class="text-info">
														{{ item.user.profile.name_user }}
													</span>
													changed status to
													<span v-if="item.status === '0'">'Leads'</span>
													<span v-else-if="item.status === '1'">'Visit'</span>
													<span v-else-if="item.status === '2'">'Approved'</span>
													<span v-else-if="item.status === '3'">'Prospect'</span>
													<span v-else-if="item.status === '4'">'Rejected'</span>
													on <b>{{ dataCustomer.name }}</b>
													<span class="text-muted" style="font-size: 11px">
														{{ format_date(item.created_at) }}</span>
													<br />
													<span>note : {{ item.remark }}</span>

													
													<span v-if="item.status === '1'">
														<br />
														Schedule time : {{ format_date(item.schedule_time) }}
													</span>
													
													<span v-if="item.status === '4'">
														<br>
														Reason : {{ isiReason }}
													</span>
													<hr />
												</span>
											</div>

											<div v-for="item in dataCustomerHistory.comment" :key=" item.id" class="col-md-12 mt-1">
												<!-- <br> -->
												<div style="display: flex;justify-content: start;align-items: center;">
													<i class="fas fa-comment mr-1"></i>
													<span class="text-info">{{ item.user.profile.name_user }}</span> comments :
													<span>
														{{item.remark}}
													</span>
													<div class="row m-0">
														<div class="col-md-12" v-show="item.trust_meter === '2'">
															<div v-b-tooltip.hover :title="item ? item.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
																<div>
																	<span>BAD</span>
																</div>
																<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
															</div>
														</div>
														<div class="col-md-12" v-show="item.trust_meter === '4'">
															<div v-b-tooltip.hover :title="item ? item.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
																<div>
																	<span>GOOD</span>
																</div>
																<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
															</div>
														</div>
														<div class="col-md-12" v-show="item.trust_meter === '3'">
															<div v-b-tooltip.hover :title="item ? item.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
																<div>
																	<span>MODERATE</span>
																</div>
																<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
															</div>
														</div>
														<div class="col-md-12" v-show="item.trust_meter === '1'">
															<div v-b-tooltip.hover :title="item ? item.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
																<div>
																	<span>UNKNOWN</span>
																</div>
																<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
															</div>
														</div>
													</div>
													<span class="text-muted" style="font-size: 11px">
														{{ format_date(item.created_at) }}</span>

													
												</div>
											</div>
									<!-- end col -->
								</div>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<!-- form modal edit-->
		<modal :click-to-close="formClose" name="editCustomer" height="auto">
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card">
						<VuePerfectScrollbar style="max-height: 500px">
							<div class="card-body">
								<h1 class="header-title"> Form Edit Customer </h1>
								<form @submit.prevent="updateDate">
									<div class="form-row mt-3">
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Company Name*</label>
												<input v-model="dataCustomer.name" type="text" class="form-control form-control-sm" />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Email*</label>
												<input v-model="dataCustomer.email" type="email" class="form-control form-control-sm" placeholder="email" />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">PIC Name*</label>
												<input v-model="dataCustomer.pic_name" type="text" class="form-control form-control-sm" placeholder="PIC Name" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Mobile Phone*</label>
												<input v-model="dataCustomer.mobile" type="number" class="form-control form-control-sm" placeholder="mobile" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">PIC Position</label>
												<input v-model="dataCustomer.position" type="text" class="form-control form-control-sm" placeholder="PIC Position" />
											</div>
										</div>

										<div class="col-md-6">
											<div class="form-group">
												<label for="">Office Phone*</label>
												<input v-model="dataCustomer.phone" type="number" class="form-control form-control-sm" placeholder="Office Phone" required />
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Address*</label>
												<textarea v-model="dataCustomer.address" maxlength="255" rows="4" placeholder="address" class="form-control form-control-sm" required></textarea>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="">Remarks</label>
												<textarea v-model="dataCustomer.remarks" maxlength="255" rows="4" placeholder="Remarks" class="form-control form-control-sm"></textarea>
											</div>
										</div>

										<div class="col-md-12">
											<span style="font-size: 16px;font-weight: 700;color: #343A40;">Customer Trust Meter</span>
										</div>
										<div class="col-md-12 mt-2" style="display: flex;">
											<div class="container-trust-meter" @click="changeTrustMeter('0')" :style="{ background: this.trustMeter !== '0' ? '#FFF' : '#86868633' }">
												<div :class="'icon-trust-meter'" style="display: flex;align-items: center;justify-content: center;">
													<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="7" cy="7.5" r="6.75" stroke="#868686" stroke-width="0.5"/>
														<line x1="12.0303" y1="2.78033" x2="2.53033" y2="12.2803" stroke="#868686" stroke-width="1.5"/>
													</svg>
												</div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '0' ? '#868686' : '#868686' }">
													<span>Not Set</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('1')" :style="{ background: this.trustMeter !== '1' ? '#FFF' : '#808080' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '1' ? '#FFF' : '#808080' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '1' ? '#FFF' : '#808080' }">
													<span>Unknown</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 80px;" @click="changeTrustMeter('2')" :style="{ background: this.trustMeter !== '2' ? '#FFF' : '#EA3131' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '2' ? '#FFF' : '#EA3131' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '2' ? '#FFF' : '#EA3131' }">
													<span>Bad</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 110px;" @click="changeTrustMeter('3')" :style="{ background: this.trustMeter !== '3' ? '#FFF' : '#F9BF2B' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '3' ? '#FFF' : '#F9BF2B' }">
													<span>Moderate</span>
												</div>
											</div>
											<div class="container-trust-meter" style="width: 85px;" @click="changeTrustMeter('4')" :style="{ background: this.trustMeter !== '4' ? '#FFF' : '#41A21F' }">
												<div class="icon-trust-meter" :style="{ background: this.trustMeter === '4' ? '#FFF' : '#41A21F' }"></div>
												<div class="text-trust-meter" :style="{ color: this.trustMeter === '4' ? '#FFF' : '#41A21F' }">
													<span>Good</span>
												</div>
											</div>
										</div>
										<div class="col-md-12 mt-2">
											<div class="form-group">
												<label for="">Notes</label>
												<input v-model="komentar" type="text" class="form-control form-control-sm" required />
											</div>
										</div>

										<div class="col-md-12">
											<div class="form-group">
												<label for="">Assignee<span class="text-danger" aria-required="true"> * </span></label>
												<v-select multiple="true" v-model="assignee" :options="listUser" :reduce="(option) => option.id" label="name_user" >
												</v-select>
											</div>
										</div>

										<div class="col-md-12 mt-1">
											<div class="float-right">
												<input type="submit" class="btn btn-create btn-sm mr-1" value="Save" />
												<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormEdit">Cancel
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</VuePerfectScrollbar>
					</div>
				</div>
			</div>
		</modal>

		<div class="d-flex" style="width: 135em">
			<!-- Leads -->
			<div class="card border bg-light w-25 mr-3" style="max-height: 100%; width: 35em !importLnt">
				<div class="card-header" style="color: #000">
					<div class="row">
						<div class="col-md-6">
							<b>Leads List</b>
							[{{listCustomerDedicated.length}}]

							<a v-can="'CONTACT_MANAGEMENT_CREATE'" style="color: mediumseagreen" href="#" @click="showFormInput"><i class="fa fa-plus"></i></a>

						</div>
						<div class="col-md-6 text-right">
							<!--							<a v-can="'CONTACT_MANAGEMENT_CREATE'" href="#" class="btn btn-create btn-sm" @click="showFormInput"><i-->
							<!--								class="fa fa-plus"></i> Add</a>-->
							<div class="form-group">
								<input v-model="searchDedicated" type="text" class="form-control form-control-sm" placeholder="Search Customer" required />
							</div>

						</div>
					</div>
				</div>
				<div class="card-body bg-light" style="padding: 10px">
					<select v-model="createdByLeads" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by" data-live-search="true" placeholder="Choose Marketing" @change="fecthDataLeads">
						<option value="null" selected>All Marketing</option>
						<option v-for="(value, key) in listUser" :key="key" :value="value.id">
							{{ value.profile.name_user }}
						</option>
					</select>
					<VuePerfectScrollbar style="max-height: 400px">
						<draggable id="0" class="list-group" :list="listCustomerDedicated" :group="{ name: 'visit', put: false }" :move="toVisit">
							<div v-for="element of resultQueryDedicated" :key="element.id" class="card border shadow" style="margin-bottom: 10px">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '2'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>BAD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '4'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>GOOD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '3'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>MODERATE</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '1'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>UNKNOWN</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-7" @click="detailLog(element.id)">
											<h5><b><i style="color: mediumseagreen" class="fas fa-city"></i>
													{{ element.name }}</b></h5>
										</div>
										<div class="col-md-5 text-right" style="position: relative;">
											<img v-b-tooltip.hover :title="element.profile ? element.profile.name_user : ''" :src="
												element.logo_user
													? element.logo_user
													: 'https://ui-avatars.com/api/?name=' + element.name
												" style="
												width: 35px;
												height: 35px;
												border-radius: 8.25rem !important;
												position: absolute;
    											right: 50px;
												" />
											
											<img v-for="(assign, index) in element.customer_assignee" :key="assign.id" v-b-tooltip.hover :title="assign.name ? assign.name : ''" :src="
												assign.logo_user
													? assign.logo_user
													: 'https://ui-avatars.com/api/?name=' + assign.name
												" :style="{
												width: '35px',
												height: '35px',
												borderRadius: '8.25rem !important',
												position: 'absolute',
    											right: index * 25 + 'px',
												}" />
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											{{ element.mobile }} <br />
											<span v-if="element.address.length > 61" style="white-space: pre-line">
												{{ element.address.slice(0,61) }} ... <br />

											</span>
											<span v-else style="white-space: pre-line">
												{{ element.address }} <br />

											</span>
											<!--											<i class="fas fa-comments"></i> {{element.remarks}} <br>-->
											<!--											<i class="fas fa-blender-phone"></i> {{element.phone}} <br>-->
											<!--											<h5 v-if="element.pic"><i class="fas fa-user"></i> {{element.pic.name}}</h5>-->
										</div>
									</div>
									<div style="margin-top: 35px" class="row ">
										<div class="col-md-6">
											<span v-show="element.updated_on !== null" style="font-size:9px;">
												Update On
												{{element.updated_on}}
											</span>
										</div>
										<div v-show="idUser.toString() === element.created_by.toString() || crmAdminEnabled" class="col-md-6 text-right">
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showComment(element.id)"><i class="fas fa-comment mr-1"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showConfirmDelete(element.id)"><i class="fas fa-trash-alt"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="#" style="color: darkgray" class="mr-1" @click="showEdit(element.id)"><i class="fas fa-edit"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="text-secondary" @click="getData(element.id)">
												<i class="far fa-calendar-alt"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</draggable>
					</VuePerfectScrollbar>
				</div>
				<div class="card-footer text-muted"> </div>
			</div>
			<!-- visit -->
			<div class="card border bg-light w-25 mr-3" style="max-height: 100%; width: 35em !important">
				<div class="card-header" style="color: #000">

					<div class="row">
						<div class="col-md-6">
							<b>Visited List</b>
							[{{listCustomerVisit.length}}]
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<input v-model="searchVisit" type="text" class="form-control form-control-sm" placeholder="Search Customer" required />
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-light" style="padding: 10px">
					<select v-model="createdByVisit" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by" data-live-search="true" placeholder="Choose Marketing" @change="fecthDataVisit">
						<option value="null" selected>All Marketing</option>
						<option v-for="(value, key) in listUser" :key="key" :value="value.id">
							{{ value.profile.name_user }}
						</option>
					</select>
					<VuePerfectScrollbar style="max-height: 400px">
						<draggable id="1" class="list-group" :list="listCustomerVisit" group="visit" :move="toVisit">
							<div v-for="element of resultQueryVisit" :key="element.id" class="card border shadow" style="margin-bottom: 10px">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '2'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>BAD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '4'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>GOOD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '3'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>MODERATE</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '1'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>UNKNOWN</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											<h5><b><i style="color: mediumseagreen" class="fas fa-city"></i>
													{{ element.name }}</b></h5>
										</div>
										<div class="col-md-4 text-right">
											<img v-b-tooltip.hover :title="element.profile ? element.profile.name_user : ''" :src="
												element.logo_user
													? element.logo_user
													: 'https://ui-avatars.com/api/?name=' + element.name
												" style="
												width: 35px;
												height: 35px;
												border-radius: 8.25rem !important;
												position: absolute;
    											right: 50px;
												" />
											
											<img v-for="(assign, index) in element.customer_assignee" :key="assign.id" v-b-tooltip.hover :title="assign.name ? assign.name : ''" :src="
												assign.logo_user
													? assign.logo_user
													: 'https://ui-avatars.com/api/?name=' + assign.name
												" :style="{
												width: '35px',
												height: '35px',
												borderRadius: '8.25rem !important',
												position: 'absolute',
    											right: index * 25 + 'px',
												}" />
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											{{ element.mobile }} <br />
											<span v-if="element.address.length > 61">
												{{ element.address.slice(0,61) }} ... <br />

											</span>
											<span v-else>
												{{ element.address }} <br />

											</span>
											<!--											<i class="fas fa-comments"></i> {{element.remarks}} <br>-->
											<!--											<i class="fas fa-blender-phone"></i> {{element.phone}} <br>-->
											<!--											<h5 v-if="element.pic"><i class="fas fa-user"></i> {{element.pic.name}}</h5>-->
										</div>
									</div>
									<div style="margin-top: 35px" class="row ">
										<div class="col-md-6">
											<span v-if="element.updated_on !== null" style="font-size:9px;">
												Update On
												{{element.updated_on}}
											</span>
										</div>
										<div v-show="idUser.toString() === element.created_by.toString() || crmAdminEnabled" class="col-md-6 text-right">
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showComment(element.id)"><i class="fas fa-comment mr-1"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showConfirmDelete(element.id)"><i class="fas fa-trash-alt"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="#" style="color: darkgray" class="mr-1" @click="showEdit(element.id)"><i class="fas fa-edit"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="text-secondary" @click="getData(element.id)">
												<i class="far fa-calendar-alt"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</draggable>
					</VuePerfectScrollbar>
				</div>
				<div class="card-footer text-muted"> </div>
			</div>
			<!-- Prospect -->
			<div class="card border bg-light w-25 mr-3" style="max-height: 100%; width: 35em !important">
				<div class="card-header" style="color: #000">

					<div class="row">
						<div class="col-md-6">
							<b>Prospect List

							</b>
							[{{listCustomerPending.length}}]
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<input v-model="searchPending" type="text" class="form-control form-control-sm" placeholder="Search Customer" required />
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-light" style="padding: 10px">
					<select v-model="createdByProspect" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by" data-live-search="true" placeholder="Choose Marketing" @change="fecthDataProspect">
						<option value="null" selected>All Marketing</option>
						<option v-for="(value, key) in listUser" :key="key" :value="value.id">
							{{ value.profile.name_user }}
						</option>
					</select>
					<VuePerfectScrollbar style="max-height: 400px">
						<draggable id="3" class="list-group" :list="listCustomerPending" group="visit" :move="toVisit">
							<div v-for="element of resultQueryPending" :key="element.id" class="card border shadow" style="margin-bottom: 10px">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '2'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>BAD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '4'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>GOOD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '3'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>MODERATE</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '1'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>UNKNOWN</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											<h5><b><i style="color: mediumseagreen" class="fas fa-city"></i>
													{{ element.name }}</b></h5>
										</div>
										<div class="col-md-4 text-right">
											<img v-b-tooltip.hover :title="element.profile ? element.profile.name_user : ''" :src="
												element.logo_user
													? element.logo_user
													: 'https://ui-avatars.com/api/?name=' + element.name
												" style="
												width: 35px;
												height: 35px;
												border-radius: 8.25rem !important;
												position: absolute;
    											right: 50px;
												" />
											
											<img v-for="(assign, index) in element.customer_assignee" :key="assign.id" v-b-tooltip.hover :title="assign.name ? assign.name : ''" :src="
												assign.logo_user
													? assign.logo_user
													: 'https://ui-avatars.com/api/?name=' + assign.name
												" :style="{
												width: '35px',
												height: '35px',
												borderRadius: '8.25rem !important',
												position: 'absolute',
    											right: index * 25 + 'px',
												}" />
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											{{ element.mobile }} <br />
											<span v-if="element.address.length > 61" style="white-space: pre-line">
												{{ element.address.slice(0,61) }} ... <br />

											</span>
											<span v-else style="white-space: pre-line">
												{{ element.address }} <br />

											</span>
											<!--											<i class="fas fa-comments"></i> {{element.remarks}} <br>-->
											<!--											<i class="fas fa-blender-phone"></i> {{element.phone}} <br>-->
											<!--											<h5 v-if="element.pic"><i class="fas fa-user"></i> {{element.pic.name}}</h5>-->
										</div>
									</div>
									<div style="margin-top: 35px" class="row ">
										<div class="col-md-6">
											<span v-if="element.updated_on !== null" style="font-size:9px;">
												Update On
												{{element.updated_on}}
											</span>
										</div>
										<div v-show="idUser.toString() === element.created_by.toString() || crmAdminEnabled" class="col-md-6 text-right">
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showComment(element.id)"><i class="fas fa-comment mr-1"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showConfirmDelete(element.id)"><i class="fas fa-trash-alt"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="#" style="color: darkgray" class="mr-1" @click="showEdit(element.id)"><i class="fas fa-edit"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="text-secondary" @click="getData(element.id)">
												<i class="far fa-calendar-alt"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</draggable>
					</VuePerfectScrollbar>
				</div>
				<div class="card-footer text-muted"> </div>
			</div>
			<!-- approve -->
			<div class="card border bg-light w-25 mr-3" style="max-height: 100%; width: 35em !important">
				<div class="card-header" style="color: #000">
					<div class="row">
						<div class="col-md-6">
							<b>Approved List</b>
							[{{listCustomerClosing.length}}]
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<input v-model="search" type="text" class="form-control form-control-sm" placeholder="Search Customer" required />
							</div>
						</div>
					</div>


				</div>
				<div class="card-body bg-light" style="padding: 10px">
					<select v-model="createdByApproved" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by" data-live-search="true" placeholder="Choose Marketing" @change="fecthDataApproved">
						<option value="null" selected>All Marketing</option>
						<option v-for="(value, key) in listUser" :key="key" :value="value.id">
							{{ value.profile.name_user }}
						</option>
					</select>
					<VuePerfectScrollbar style="max-height: 400px">
						<draggable id="2" class="list-group" :list="listCustomerClosing" group="visit" handle=".handle">
							<div v-for="element of resultQuery" :key="element.id" class="card border shadow" style="margin-bottom: 10px">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '2'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>BAD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '4'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>GOOD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '3'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>MODERATE</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '1'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>UNKNOWN</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											<h5><b><i style="color: mediumseagreen" class="fas fa-city"></i>
													{{ element.name }}</b></h5>
										</div>
										<div class="col-md-4 text-right">
											<img v-b-tooltip.hover :title="element.profile ? element.profile.name_user : ''" :src="
												element.logo_user
													? element.logo_user
													: 'https://ui-avatars.com/api/?name=' + element.name
												" style="
												width: 35px;
												height: 35px;
												border-radius: 8.25rem !important;
												position: absolute;
    											right: 50px;
												" />
											
											<img v-for="(assign, index) in element.customer_assignee" :key="assign.id" v-b-tooltip.hover :title="assign.name ? assign.name : ''" :src="
												assign.logo_user
													? assign.logo_user
													: 'https://ui-avatars.com/api/?name=' + assign.name
												" :style="{
												width: '35px',
												height: '35px',
												borderRadius: '8.25rem !important',
												position: 'absolute',
    											right: index * 25 + 'px',
												}" />
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											{{ element.mobile }} <br />
											<span v-if="element.address.length > 61" style="white-space: pre-line">
												{{ element.address.slice(0,61) }} ... <br />

											</span>
											<span v-else style="white-space: pre-line">
												{{ element.address }} <br />

											</span>
											<!--											<i class="fas fa-comments"></i> {{element.remarks}} <br>-->
											<!--											<i class="fas fa-blender-phone"></i> {{element.phone}} <br>-->
											<!--											<h5 v-if="element.pic"><i class="fas fa-user"></i> {{element.pic.name}}</h5>-->
										</div>
									</div>
									<div style="margin-top: 35px" class="row">
										<div class="col-md-6">
											<span v-if="element.updated_on !== null" style="font-size:9px;">
												Update On
												{{element.updated_on}}
											</span>
										</div>
										<div v-show="idUser.toString() === element.created_by.toString() || crmAdminEnabled" class="col-md-6 text-right">
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showComment(element.id)"><i class="fas fa-comment mr-1"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showConfirmDelete(element.id)"><i class="fas fa-trash-alt"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="#" style="color: darkgray" class="mr-1" @click="showEdit(element.id)"><i class="fas fa-edit"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="text-secondary" @click="getData(element.id)">
												<i class="far fa-calendar-alt"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</draggable>
					</VuePerfectScrollbar>
				</div>
				<div class="card-footer text-muted"> </div>
			</div>

			<!-- reject -->
			<div class="card border bg-light w-25 mr-3" style="max-height: 100%; width: 35em !important">
				<div class="card-header" style="color: #000">

					<div class="row">
						<div class="col-md-6">
							<b>Rejected List</b>
							[{{listCustomerReject.length}}]
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<input v-model="searchReject" type="text" class="form-control form-control-sm" placeholder="Search Customer" required />
							</div>
						</div>
					</div>
				</div>
				<div class="card-body bg-light" style="padding: 10px">
					<select v-model="createdByRejected" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by" data-live-search="true" placeholder="Choose Marketing" @change="fecthDataRejected">
						<option value="null" selected>All Marketing</option>
						<option v-for="(value, key) in listUser" :key="key" :value="value.id">
							{{ value.profile.name_user }}
						</option>
					</select>
					<VuePerfectScrollbar style="max-height: 400px">
						<draggable id="4" class="list-group" :list="listCustomerReject" group="visit" :move="toVisit">
							<div v-for="element of resultQueryReject" :key="element.id" class="card border shadow" style="margin-bottom: 10px">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '2'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 70px;background: #EA3131;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>BAD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '4'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 80px;background: #41A31F;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>GOOD</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '3'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #F9BF2C;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>MODERATE</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-12" v-show="element.customer_history.trust_meter === '1'">
											<div v-b-tooltip.hover :title="element.customer_history ? element.customer_history.remark : ''" style="width: 115px;background: #808080;color: #FFF;font-weight: bold;padding: 2px;border-radius: 20px;padding-left: 10px;display: flex;justify-content: start;align-items: center;">
												<div>
													<span>UNKNOWN</span>
												</div>
												<div style="width: 10px;height: 10px;background: #FFF;border-radius: 50%;margin-left: 10px;"></div>
											</div>
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											<h5><b><i style="color: mediumseagreen" class="fas fa-city"></i>
													{{ element.name }}</b></h5>
										</div>
										<div class="col-md-4 text-right">
											<img v-b-tooltip.hover :title="element.profile ? element.profile.name_user : ''" :src="
												element.logo_user
													? element.logo_user
													: 'https://ui-avatars.com/api/?name=' + element.name
												" style="
												width: 35px;
												height: 35px;
												border-radius: 8.25rem !important;
												position: absolute;
    											right: 50px;
												" />
											
											<img v-for="(assign, index) in element.customer_assignee" :key="assign.id" v-b-tooltip.hover :title="assign.name ? assign.name : ''" :src="
												assign.logo_user
													? assign.logo_user
													: 'https://ui-avatars.com/api/?name=' + assign.name
												" :style="{
												width: '35px',
												height: '35px',
												borderRadius: '8.25rem !important',
												position: 'absolute',
    											right: index * 25 + 'px',
												}" />
										</div>
										<div class="col-md-8" @click="detailLog(element.id)">
											{{ element.mobile }} <br />
											<span v-if="element.address.length > 61" style="white-space: pre-line">
												{{ element.address.slice(0,61) }} ... <br />

											</span>
											<span v-else style="white-space: pre-line">
												{{ element.address }} <br />

											</span>
											<!--											<i class="fas fa-comments"></i> {{element.remarks}} <br>-->
											<!--											<i class="fas fa-blender-phone"></i> {{element.phone}} <br>-->
											<!--											<h5 v-if="element.pic"><i class="fas fa-user"></i> {{element.pic.name}}</h5>-->
										</div>
									</div>
									<div style="margin-top: 35px" class="row ">
										<div class="col-md-6">
											<span v-if="element.updated_on !== null" style="font-size:9px;">
												Update On
												{{element.updated_on}}
											</span>
										</div>
										<div v-show="idUser.toString() === element.created_by.toString() || crmAdminEnabled" class="col-md-6 text-right">
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showComment(element.id)"><i class="fas fa-comment mr-1"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_DELETE'" href="#" style="color: darkgray" class="mr-1" @click="showConfirmDelete(element.id)"><i class="fas fa-trash-alt"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_UPDATE'" href="#" style="color: darkgray" class="mr-1" @click="showEdit(element.id)"><i class="fas fa-edit"></i></a>
											<a v-can="'CONTACT_MANAGEMENT_SCHEDULE_ENABLE'" href="javascript:void(0);" class="text-secondary" @click="getData(element.id)">
												<i class="far fa-calendar-alt"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</draggable>
					</VuePerfectScrollbar>
				</div>
				<div class="card-footer text-muted"> </div>
			</div>
		</div>
	</div>
</template>


<script>
	import draggable from 'vuedraggable'
	import Swal from 'sweetalert2'
	// import tinymce from 'vue-tinymce-editor'
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import datetime from 'vuejs-datetimepicker'
	import {
		mapActions,
		//   mapMutations,
		mapState,
	} from 'vuex'
	import moment from 'moment'
	import axios from 'axios'
	import VueSimpleSuggest from 'vue-simple-suggest'
	import 'vue-simple-suggest/dist/styles.css' // Optional CSS

	var url = '/customer'
	// var status = false

	for (
		let i = 0; i < JSON.parse(localStorage.getItem('userPermissions')).length; i++
	) {

		if (
			JSON.parse(localStorage.getItem('userPermissions'))[i] ===
			'CRM_ADMIN_ENABLE'
		) {
			url = '/customer?admin=true'
		}
	}

	export default {
		components: {
			draggable,
			datetime,
			// tinymce,
			VueSimpleSuggest,
			VuePerfectScrollbar,
		},
		props: {
			showActions: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				dataNewCustomer: {
					status: 0,
				},
				formClose: false,
				dataHistoryCustomer: {
					// status:
				},
				autoCompleteStyle: {
					vueSimpleSuggest: "position-relative",
					inputWrapper: "",
					defaultInput: "form-control form-control-sm",
					suggestions: "position-absolute list-group z-1000",
					suggestItem: "list-group-item"
				},
				getList: [],
				search: '',
				searchReject: '',
				searchPending: '',
				searchVisit: '',
				searchDedicated: '',
				deleteData: {},
				listStatus: [{
						id: 0,
						// name: 'Dedicated'
						name: 'Leads',
					},
					{
						id: 1,
						name: 'Visit',
					},
					{
						id: 2,
						// name: 'Closing'
						name: 'Approve',
					},
					{
						id: 3,
						// name: 'Closing'
						name: 'Prospect',
					},
					{
						id: 4,
						// name: 'Closing'
						name: 'Reject',
					},
				],
				logoUser: '',
				user: '',
				remark: '',
				komentar: '',
				newStatus: null,
				isLoading: false,
				historyStatus: {
					height: 150,
				},
				year: null,
				createdByLeads: null,
				createdByVisit: null,
				createdByProspect: null,
				createdByApproved: null,
				createdByRejected: null,
				status: null,
				pic: null,
				idStatus: null,
				reason: null,
				idReason: null,
				plugins: ['code'],
				urlCi: process.env.VUE_APP_CI_URL,
				toolbars: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
				trustMeter: 0,
				assignee: [],
				listAssigner: [],
				idUser: localStorage.getItem('session_user'),
				crmAdminEnabled: JSON.parse(localStorage.getItem('userPermissions')).includes('CRM_ADMIN_ENABLE')
			}
		},
		computed: {
			...mapState('customer', [
				'listCustomerDedicated',
				'listCustomerVisit',
				'listCustomerClosing',
				'listCustomerPending',
				'listCustomerReject',
				'dataCustomer',
			]),
			...mapState('customerHistory', ['dataCustomerHistory']),
			...mapState('masterReason', ['listReason']),
			...mapState('user', ['listUser', 'dataUser']),


			resultQuery() {
				if (this.search) {
					return this.listCustomerClosing.filter(item => {
						return this.search
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomerClosing;
				}
			},
			resultQueryReject() {
				if (this.searchReject) {
					return this.listCustomerReject.filter(item => {
						return this.searchReject
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomerReject;
				}
			},
			resultQueryPending() {
				if (this.searchPending) {
					return this.listCustomerPending.filter(item => {
						return this.searchPending
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomerPending;
				}
			},
			resultQueryVisit() {
				if (this.searchVisit) {
					return this.listCustomerVisit.filter(item => {
						return this.searchVisit
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomerVisit;
				}
			},
			resultQueryDedicated() {
				if (this.searchDedicated) {
					return this.listCustomerDedicated.filter(item => {
						return this.searchDedicated
							.toLowerCase()
							.split(" ")
							.every(v => item.name.toLowerCase().includes(v));
					});
				} else {
					return this.listCustomerDedicated;
				}
			}
		},
		mounted() {
			this.getListCustomerDedicated()
			this.getListCustomerVisit()
			this.getListCustomerClosing()
			this.getListCustomerPending()
			this.getListCustomerReject()
			this.getListReason()
			this.getUser()
		},
		methods: {
			...mapActions('customer', [
				'getListCustomerDedicated',
				'getListCustomerVisit',
				'getListCustomerClosing',
				'getListCustomerPending',
				'getListCustomerReject',
				'saveCustomer',
				'getCustomer',
				'updateCustomer',
				'deleteCustomer',
			]),
			...mapActions('customerHistory', [
				'saveCustomerHistory',
				'getCustomerHistory',
			]),
			...mapActions('user', ['getListUser', 'getUsername']),
			...mapActions('masterReason', ['getListReason']),
			test() {
				this.getList = []
				axios.get(url).then((response) => {
					// console.log(response.data, 'data customer')
					for (let i = 0; i < response.data.length; i++) {
						const element = response.data[i];

						if(element.customer_history == null) {
							element.customer_history = {
								trust_meter: 0,
								remark: ''
							}
						}

						this.getList.push({
							id: element.id,
							name: element.name + ' *customer already exists',
							customer_history: element.customer_history,
							disabled: true
						})
					}

				}, () => {
					console.log("gagal")
				});
			},
			getCustomerList() {

				return this.getList

			},
			format_date(value) {
				if (value) {
					return moment(String(value)).format('DD  MMM YYYY, h:mm:ss a')
				}
			},
			getUser() {
				try {
					this.getListUser()
						.then((res) => {
							console.log(res, 'res user')
							// this.user_id = res.result[1].id
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			changeStatus(value) {
				this.dataCustomer.value_status = value.name
				this.dataCustomer.status = value.id
				this.status = value.name
				this.idStatus = value.id
				console.log(this.status, 'status')
			},
			changeReason(value) {
				this.reason = value
				this.dataCustomerHistory.id_reason = value.id
				this.idReason = value.id
				console.log(this.dataCustomerHistory.id_reason, 'id reason')
			},

			showFormInput() {
				this.trustMeter = '0'
				this.$modal.show('inputCustomer')
				this.test()
			},
			hideFormInput() {
				this.$modal.hide('inputCustomer')
				this.dataNewCustomer = {}
			},
			hideFormSchedule(evt, originalEvent) {
				// eslint-disable-next-line no-unused-expressions
				originalEvent === false
				this.getListCustomerDedicated()
				this.getListCustomerVisit()
				this.getListCustomerClosing()
				this.getListCustomerPending()
				this.getListCustomerReject()
				this.$modal.hide('scheduleCustomer')
				this.dataCustomer = {}
			},
			hideFormKomentar(evt, originalEvent) {
				// eslint-disable-next-line no-unused-expressions
				originalEvent === false
				this.getListCustomerDedicated()
				this.getListCustomerVisit()
				this.getListCustomerClosing()
				this.getListCustomerPending()
				this.getListCustomerReject()
				this.$modal.hide('commentCustomer')
				this.dataCustomer = {}

			},

			hideFormEdit(evt, originalEvent) {
				// eslint-disable-next-line no-unused-expressions
				originalEvent === false
				this.getListCustomerDedicated()
				this.getListCustomerVisit()
				this.getListCustomerClosing()
				this.getListCustomerPending()
				this.getListCustomerReject()
				this.$modal.hide('editCustomer')
				this.dataCustomer = {}
			},

			detailLog: function (id) {
				this.$modal.show('logCustomer')
				this.getCustomer(id)
					.then((res) => {
						// this.dataCustomer = res
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
				this.getCustomerHistory(id)
					.then((res) => {
						// this.dataCustomerHistory = res
						console.log(this.dataCustomerHistory, 'fdfds')
						for (let i = 0; i < this.dataCustomerHistory.length; i++) {
							// const element = array[i];

							console.log(this.dataCustomerHistory[i].created_by, 'creat by')
							this.getUsername(this.dataCustomerHistory[i].created_by)
								.then((resUser) => {
									var usernameUser = this.dataUser.username_user
									var username = usernameUser.split('@')

									this.dataCustomerHistory[i].created_by = username[0]
								})
								.catch((err) => {
									console.log(err)
								})
						}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			inputData() {
				// check if data already exists by name
				axios.get('/customerName?name=' + this.dataNewCustomer.name).then((response) => {
					console.log(response.data, 'data customer')
					if (response.data.length === 0) {
						let headers = {
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + localStorage.getItem('access_token'),
						}

						this.dataNewCustomer.status = 0
						this.dataNewCustomer.trust_meter = this.trustMeter
						this.dataNewCustomer.notes = this.komentar
						this.dataNewCustomer.assignee = this.assignee

						this.saveCustomer({
								payload: this.dataNewCustomer,
								headers: headers,
							})
							.then((res) => {
								const Toast = Swal.mixin({
									toast: true,
									position: 'bottom-end',
									showConfirmButton: false,
									timer: 2000,
									timerProgressBar: true,
									onOpen: (toast) => {
										toast.addEventListener('mouseenter', Swal.stopTimer)
										toast.addEventListener('mouseleave', Swal.resumeTimer)
									},
								})

								Toast.fire({
									icon: 'success',
									title: 'successfully',
								})
								this.getListCustomerDedicated()
								this.getListCustomerVisit()
								this.getListCustomerClosing()
								this.getListCustomerPending()
								this.getListCustomerReject()
								this.$modal.hide('inputCustomer')
								console.log(res)
								this.dataNewCustomer = {}
							})
							.catch((err) => {
								console.log(err)
								this.errorAlert('Failed save data')
							})
					} else {
						Swal.fire('Warning!', 'Customer Already Exists.', 'warning')
					}

				}, () => {
					console.log("gagal")
				});

			},

			toVisit: async function (evt) {
				// console.log(evt, 'context');
				var toStatus = evt.to.id
				setTimeout(
					function () {
						this.$modal.show('scheduleCustomer')
					}.bind(this),
					700
				)

				let id = evt.draggedContext.element.id
				await this.getCustomer(id)
					.then((res) => {
						// this.status = this.dataCustomer.status
						for (let i = 0; i < this.listStatus.length; i++) {
							// eslint-disable-next-line eqeqeq
							if (i == toStatus) {
								this.status = this.listStatus[i].name
								this.idStatus = this.listStatus[i].id
							}
						}

						console.log(this.status, 'status skrg')
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},
			showEdit: function (id) {
				this.$modal.show('editCustomer')
				this.getCustomer(id)
					.then((res) => {
						if(this.dataCustomer.customer_history !== null && this.dataCustomer.customer_history !== undefined) {
							this.trustMeter = this.dataCustomer.customer_history.trust_meter
							this.komentar = this.dataCustomer.customer_history.remark
							this.assignee = []
							this.dataCustomer.customer_assignee.forEach(element => {
								this.assignee.push(element.id_user)
							});
						}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},

			getData: function (id) {
				this.$modal.show('scheduleCustomer')
				this.getCustomer(id)
					.then((res) => {

					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},

			showComment: function (id) {
				this.$modal.show('commentCustomer')
				this.getCustomer(id)
					.then((res) => {

					})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
				
				this.trustMeter = '0'
			},
			updateDate() {
				this.dataCustomer.trust_meter = this.trustMeter
				this.dataCustomer.notes = this.komentar
				this.dataCustomer.assignee = this.assignee

				// update customer
				this.updateCustomer({
						payload: this.dataCustomer,
						id: this.dataCustomer.id,
					})
					.then((res) => {
						// console.log(res)
						this.getListCustomerDedicated()
						this.getListCustomerVisit()
						this.getListCustomerClosing()
						this.getListCustomerPending()
						this.getListCustomerReject()
					})

					.catch((err) => {
						console.log(err)
						this.errorAlert('failed update data')
					})

				this.successAlert('Successfully')
				this.$modal.hide('editCustomer')
			},
			showConfirmDelete: function (id) {
				this.deleteData.id = id

				let self = this

				Swal.fire({
					title: 'Are you sure?',
					text: 'You will not be able to recover this data!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					cancelButtonColor: '#C1C1C1',
					reverseButtons: true,
					confirmButtonText: 'Yes, delete it!',
				}).then((result) => {
					if (result.isConfirmed) {
						self.onDelete()
						Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
					}
				})
			},
			onDelete() {
				this.deleteCustomer(this.deleteData.id)
					.then((res) => {
						this.getListCustomerDedicated()
						this.getListCustomerVisit()
						this.getListCustomerClosing()
						this.getListCustomerPending()
						this.getListCustomerReject()
						this.successAlert()
					})
					.catch((err) => {
						this.errorAlert()
						console.log(err)
					})
			},
			fecthDataLeads() {
				try {
					console.log('isUser', this.createdByLeads)
					this.getListCustomerDedicated(this.createdByLeads)
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			fecthDataVisit() {
				try {
					console.log('isUser', this.createdByVisit)
					this.getListCustomerVisit(this.createdByVisit)
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			fecthDataProspect() {
				try {
					console.log('isUser', this.createdByProspect)
					this.getListCustomerPending(this.createdByProspect)
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			fecthDataApproved() {
				try {
					console.log('isUser', this.createdByApproved)
					this.getListCustomerClosing(this.createdByApproved)
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			fecthDataRejected() {
				try {
					console.log('isUser', this.createdByRejected)
					this.getListCustomerReject(this.createdByRejected)
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
				} catch (error) {
					console.log(error)
				}
			},
			// formatTgl() {
			// 	if (this.dataCustomer.schedule_time) {
			// 		return moment(String(this.dataCustomer.schedule_time)).format('MM-DD-YYYY hh:mm:ss')
			// 	}
			// console.log(this.dataCustomer.schedule_time)
			// },
			addCommentar() {
				let headers = {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				}

				console.log(this.komentar, 'namanya')
				this.dataHistoryCustomer.id_customer = this.dataCustomer.id
				this.dataHistoryCustomer.status = this.dataCustomer.status
				this.dataHistoryCustomer.remark = this.komentar
				this.dataHistoryCustomer.is_commentar = 1
				this.dataHistoryCustomer.trust_meter = this.trustMeter
				this.saveCustomerHistory({
						payload: this.dataHistoryCustomer,
						headers: headers,
					})
					.then((res) => {
						// console.log(res, 'update customer history')

						this.getListCustomerDedicated()
						this.getListCustomerVisit()
						this.getListCustomerClosing()
						this.getListCustomerPending()
						this.getListCustomerReject()
						// this.dataNewCustomer = {}
						this.dataCustomer.schedule_time = null
						this.komentar = null
						this.$modal.hide('commentCustomer')
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							},
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully',
						})
					})
					.catch((err) => {
						console.log(err)
					})
			},
			submitData() {
				let headers = {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				}
				// let tgl = moment(String(this.dataCustomer.schedule_time)).format('YYYY-MM-DD hh:mm:ss')
				console.log(this.dataCustomer.status, 'status cust')
				let tgl = this.dataCustomer.schedule_time
				this.dataCustomer.status = this.idStatus
				this.dataHistoryCustomer.id_customer = this.dataCustomer.id
				this.dataHistoryCustomer.status = this.idStatus
				this.dataHistoryCustomer.id_reason = this.idReason
				this.dataHistoryCustomer.remark = this.remark
				this.dataHistoryCustomer.schedule_time = tgl
				this.dataHistoryCustomer.trust_meter = this.trustMeter
				console.log('tgl', tgl)
				console.log(this.idStatus, 'status')
				console.log(this.idReason, 'reason')
				if (this.idStatus === 1) {
					if (tgl == null) {
						// console.log('tgl kosong')
						this.errorAlert('Date schedule cannot be null!')
					} else {
						this.updateCustomer({
								payload: this.dataCustomer,
								id: this.dataCustomer.id,
							})
							.then((res) => {
								console.log(res)
								this.dataHistoryCustomer.trust_meter = this.dataCustomer.customer_history.trust_meter
								// input historoy
								this.saveCustomerHistory({
										payload: this.dataHistoryCustomer,
										headers: headers,
									})
									.then((res) => {
										console.log(res)
										this.getListCustomerDedicated()
										this.getListCustomerVisit()
										this.getListCustomerClosing()
										this.getListCustomerPending()
										this.getListCustomerReject()
									})
									.catch((err) => {
										console.log(err)
									})
							})
							.catch((err) => {
								console.log(err)
								this.errorAlert('failed update data')
							})

						this.successAlert('Successfully')
						this.dataCustomer.schedule_time = null
						this.remark = null
						this.$modal.hide('scheduleCustomer')
					}
				} else {
					// console.log('tgl ada')

					// update customer
					this.updateCustomer({
							payload: this.dataCustomer,
							id: this.dataCustomer.id,
						})
						.then((res) => {
							console.log(res, 'update customer')
							this.dataHistoryCustomer.trust_meter = this.dataCustomer.customer_history.trust_meter
							// input historoy
							this.saveCustomerHistory({
									payload: this.dataHistoryCustomer,
									headers: headers,
								})
								.then((res) => {
									console.log(res, 'update customer history')

									this.getListCustomerDedicated()
									this.getListCustomerVisit()
									this.getListCustomerClosing()
									this.getListCustomerPending()
									this.getListCustomerReject()
									// this.dataNewCustomer = {}
									this.dataCustomer.schedule_time = null
									this.remark = null
									this.$modal.hide('scheduleCustomer')
									const Toast = Swal.mixin({
										toast: true,
										position: 'bottom-end',
										showConfirmButton: false,
										timer: 2000,
										timerProgressBar: true,
										onOpen: (toast) => {
											toast.addEventListener('mouseenter', Swal.stopTimer)
											toast.addEventListener('mouseleave', Swal.resumeTimer)
										},
									})

									Toast.fire({
										icon: 'success',
										title: 'successfully',
									})

									if (this.dataCustomer.status === 2) {
										window.location.href =
											this.urlCi +
											'/admin/master_customer/edit/' +
											this.dataCustomer.id
									}
								})
								.catch((err) => {
									console.log(err)
								})
						})
						.catch((err) => {
							console.log(err)
							this.errorAlert('failed update data')
						})
				}
			},
			changeTrustMeter(trustMeter) {
				this.trustMeter = trustMeter
			},
			getLabel (item) {
				return item.name
			},
			updateItems (text) {
				console.log(text, 'this text')
				// yourGetItemsMethod(text).then( (response) => {
				// 	this.items = response
				// })
			},
			onSuggestSelect () {
				console.log('OKE')
			},
			onAutocompleteButtonClick(suggestion = '') {
				console.log(suggestion, 'this suggest')
				// this.$refs.suggest.autocompleteText(suggestion);
			}
		},
	}

</script>
